import { GetterTree, ActionTree, MutationTree } from "vuex";

type TimeframeViewState = ReturnType<typeof state>;

export const state = () => ({
  timeframeViewState: "day",
});

export const getters: GetterTree<TimeframeViewState, any> = {
  getTimeframeViewState: (state) => state.timeframeViewState,
};

export const actions: ActionTree<TimeframeViewState, any> = {
  setTimeframeViewState({ commit }, timeframeViewState: string) {
    commit("SET_TIMEFRAME_VIEW_STATE", timeframeViewState);
  },
};

export const mutations: MutationTree<TimeframeViewState> = {
  SET_TIMEFRAME_VIEW_STATE(state, timeframeViewState: string) {
    state.timeframeViewState = timeframeViewState;
  },
};
