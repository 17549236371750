export default function ({ redirect }) {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    alert("問診作成はモバイル対応されていません。パソコンをご利用ください。");
    redirect({ path: "/form/submissions" });
  }
}
