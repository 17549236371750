
import { mapState } from "vuex";
import Header from "@/components/layout/Header.vue";
import Footer from "@/components/layout/Footer.vue";
import OnProgress from "@/components/parts/OnProgress.vue";
import { provide } from "vue";
import { FeatureFlag } from "~/domain/featureFlag/FeatureFlag";
import { FeatureFlagResolver } from "~/domain/featureFlag/FeatureFlagResolver";
// import * as Sentry from '@sentry/browser';
// import { Vue as VueIntegration } from '@sentry/integrations';
// import { Integrations } from '@sentry/tracing';
// import Vue from 'vue';

// if (process.env.STAGE === 'PRD') {
//   Sentry.init({
//     dsn: 'https://102cee689e8d49d68ccf3c3c402880ef@o556053.ingest.sentry.io/6239323',
//     integrations: [
//       new VueIntegration({
//         Vue,
//         tracing: true
//       }),
//       new Integrations.BrowserTracing()
//     ],
//     tracesSampleRate: 1.0
//   })
// }

export default {
  setup() {
    provide<FeatureFlag>(
      "featureFlag",
      new FeatureFlagResolver().getHardCodeFeatureFlag()
    );
  },
  components: { OnProgress, Header, Footer },
  name: "default",
  computed: {
    ...mapState("site", ["progressMessage", "breadcrumbs"]),
  },
};
