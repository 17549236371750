export const noEmptyRule = [(v: string) => !!v || "入力してください"];

export const noEmptyRules = (i18n: any) => [
  (v: string) => {
    if (!!v) return true;
    return i18n.t("formValidation.pleaseEnter");
  },
];

export const noSpaceOrEmptyRules = (i18n: any) => [
  (v: string | null | undefined) => {
    if (typeof v === "string" && v.trim()) return true;
    return i18n.t("formValidation.pleaseEnterNoSpace");
  },
];
