import { Clinic } from "@ambii-com/core";
import { fsLocations } from "~/dao/FsLocations";
import { auth } from "~/setups/firebase";
import { signOut } from "firebase/auth";
import { GetterTree, ActionTree, MutationTree } from "vuex";
import { functions } from "~/setups/firebase";
import type { HttpsCallableResult } from "@firebase/functions-types";

export const state = () => ({
  userId: null,
  userRole: null,
  clinicId: null,
  clinicName: null,
  formConfig: null,
  superAdmin: null,
  clinicList: null,
  algoliaApiKeyPromise: null as Promise<HttpsCallableResult>,
  formActive: null,
  appointmentActive: null,
  homepageActive: null,
});

type AuthState = ReturnType<typeof state>;

export const getters: GetterTree<AuthState, any> = {
  getUserId: (state) => state.userId,
  isLoggedIn: (state) => !!state.userId,
  getClinicId: (state) => state.clinicId,
  getClinicName: (state) => state.clinicName,
  getFormConfig: (state) => state.formConfig,
  checkSuperadmin: (state) => state.superAdmin,
  getClinicList: (state) => state.clinicList,
  getUserRole: (state) => state.userRole,
  getAlgoliaAPIKeyPromise: (state) => state.algoliaApiKeyPromise,
  getFormActive: (state) => state.formActive,
  getAppointmentActive: (state) => state.appointmentActive,
  getHomepageActive: (state) => state.homepageActive,
};

export const actions: ActionTree<AuthState, any> = {
  setUser({ commit }, user) {
    commit("SET_USER", user);
  },
  // setUserRole({ commit }, role) {
  //     commit("SET_USER_ROLE", role)
  // },
  logout({ commit }) {
    signOut(auth).then(() => {
      localStorage.removeItem("superadminEmail");
      localStorage.removeItem("clinicId");
      commit("LOGOUT");
    });
  },
  setClinicId({ commit }, clinicId) {
    commit("SET_CLINICID", clinicId);
  },
  setClinicName({ commit }, clinicName) {
    commit("SET_CLINICNAME", clinicName);
  },
  setFormConfig({ commit }, formConfig) {
    commit("SET_FORMCONFIG", formConfig);
  },
  setSuperadmin({ commit }, superadmin) {
    commit("SET_SUPERADMIN", superadmin);
  },
  setUserRole({ commit }, userRole) {
    commit("SET_USERROLE", userRole);
  },
  setFormActive({ commit }, formActive) {
    commit("SET_FORMACTIVE", formActive);
  },
  setAppointmentActive({ commit }, appointmentActive) {
    commit("SET_APPOINTMENTACTIVE", appointmentActive);
  },
  setHomepageActive({ commit }, homepageActive) {
    commit("SET_HOMEPAGEACTIVE", homepageActive);
  },
  async setClinicList({ commit }) {
    const clinicList = await fsLocations
      .getClinicsRef()
      .get()
      .then(({ docs }) => Clinic.fromDocs(docs));
    commit("SET_CLINICLIST", clinicList);
  },
  async setAlgoliaAPIKeyPromise({ state, commit }) {
    // currently no expiration for key
    const result: Promise<HttpsCallableResult> = functions.httpsCallable(
      "generateSecuredAPIKey"
    )({
      clinicId: state.clinicId,
    });
    commit("SET_ALGOLIA_API_KEY_PROMISE", result);
  },
};

export const mutations: MutationTree<AuthState> = {
  SET_USER(state, user) {
    state.userId = user.uid;
  },
  SET_USERROLE(state, role) {
    state.userRole = role;
  },
  LOGOUT(state) {
    state.clinicId = state.userId = state.clinicName = null;
  },
  SET_CLINICID(state, clinicId) {
    state.clinicId = clinicId;
  },
  SET_CLINICNAME(state, clinicName) {
    state.clinicName = clinicName;
  },
  SET_FORMCONFIG(state, formConfig) {
    state.formConfig = formConfig;
  },
  SET_SUPERADMIN(state, superAdmin) {
    state.superAdmin = superAdmin;
  },
  SET_CLINICLIST(state, clinicList) {
    state.clinicList = clinicList;
  },
  SET_ALGOLIA_API_KEY_PROMISE(
    state,
    httpCallableResult: Promise<HttpsCallableResult>
  ) {
    state.algoliaApiKeyPromise = httpCallableResult;
  },
  SET_FORMACTIVE(state, formActive) {
    state.formActive = formActive;
  },
  SET_APPOINTMENTACTIVE(state, appointmentActive) {
    state.appointmentActive = appointmentActive;
  },
  SET_HOMEPAGEACTIVE(state, homepageActive) {
    state.homepageActive = homepageActive;
  },
};
