import { FirebaseFirestore } from "@firebase/firestore-types";
import firebase from "firebase/compat/app";
import { getAuth, Auth, EmailAuthProvider } from "firebase/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import "firebase/compat/functions";
import prd from "~/configs/firebase-prd";
import dev from "~/configs/firebase-dev";
import stg from "~/configs/firebase-stg";
import { getStorage } from "firebase/storage";

let app: firebase.app.App | undefined;

if (!firebase.apps.length) {
  if (process.env.STAGE === "PRD") {
    app = firebase.initializeApp(prd);
  } else if (process.env.STAGE === "STG") {
    app = firebase.initializeApp(stg);
  } else if (process.env.STAGE === "DEV") {
    app = firebase.initializeApp(dev);
  } else {
    // when running tests
    app = firebase.initializeApp(dev);
  }
  // if(firebase.analytics.isSupported()) {
  //   firebase.analytics()
  // }
}

export const signInOptions = [EmailAuthProvider.PROVIDER_ID];

export const fieldPath = firebase.firestore.FieldPath;
export const fieldValue = firebase.firestore.FieldValue;
export const auth: Auth = getAuth(app);

export const db: FirebaseFirestore = firebase.firestore();
export const functions: firebase.functions.Functions = firebase
  .app()
  .functions("asia-northeast1");
export const storage = getStorage();
// Use if using firestore emulator
// if (location.hostname === "localhost") {
//   db.useEmulator("localhost", 8080);
// }
