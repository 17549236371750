import { Context } from "@nuxt/types";
import { auth } from "~/setups/firebase";
import { fsLocations } from "~/dao/FsLocations";
import { Clinic } from "@ambii-com/core";
import { FormConfig } from "@ambii-com/core";
import { onAuthStateChanged } from "firebase/auth";
import PermissionService from "~/services/PermissionService";

export default function ({ app, store }: Context) {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, async (user: any) => {
      if (user) {
        store.dispatch("auth/setUser", user);
        const adminUser = await fsLocations
          .getAdminsRef(user.uid)
          .get()
          .then((doc) => doc.data());

        const superadminCheckResponse =
          await PermissionService.getPermissionCheck(user.uid)
            .then((response) => {
              return response;
            })
            .catch((err) => {
              return err.response;
            });
        if (
          superadminCheckResponse.data.data.user_type ==
          app.$enums.UserType.SUPERADMIN
        ) {
          localStorage.setItem("superadminEmail", user.email);
          store.dispatch("auth/setSuperadmin", true);
          store.dispatch("auth/setClinicList");
        } else {
          store.dispatch("auth/setClinicId", adminUser.clinicId);
          store.dispatch("auth/setUserRole", adminUser.role);
          const clinic = await fsLocations
            .getClinicRef(adminUser.clinicId)
            .get()
            .then(Clinic.fromDoc);
          store.dispatch("auth/setClinicName", clinic.name);
          store.dispatch(
            "auth/setFormActive",
            clinic.activeServices.includes("FORM")
          );
          store.dispatch(
            "auth/setAppointmentActive",
            clinic.activeServices.includes("APPOINTMENT")
          );
          store.dispatch(
            "auth/setHomepageActive",
            clinic.activeServices.includes("HOMEPAGE")
          );
          const formConfig = await fsLocations
            .getFormConfigRef(clinic.formConfigId)
            .get()
            .then(FormConfig.fromDoc);
          store.dispatch("auth/setFormConfig", formConfig);
        }
        // cache algolia secret key function response
        if (!store.getters["auth/getAlgoliaAPIKeyPromise"]) {
          store.dispatch("auth/setAlgoliaAPIKeyPromise");
        }
      }

      // TODO
      resolve(null);
    });
  });
}
