import { fsLocations } from "~/dao/FsLocations";
import { UserRole } from "@ambii-com/core";

export default async function ({ store, redirect }) {
  if (store.getters["auth/getUserRole"] === "STAFF") {
    // TODO: add to UserRole enum
    alert(
      "こちらのページは権限がありません。管理者のアカウントでログインしてください。"
    );
    redirect({ path: "/form/submissions" });
  }
}
