import joinPath from "path.join";
import { noEmptyRules, noSpaceOrEmptyRules } from "~/utils/formValidation";

export default (context, inject) => {
  inject("join", joinPath);
  inject("parentPath", (path: string) =>
    path.split("/").slice(0, -1).join("/")
  );
  // inject("noEmptyRules", noEmptyRules);
  // inject("noSpaceOrEmptyRules", noSpaceOrEmptyRules);
  inject("noEmptyRules", noEmptyRules(context.i18n));
  inject("noSpaceOrEmptyRules", noSpaceOrEmptyRules(context.i18n));
};
