import { FeatureFlag } from "./FeatureFlag";

export class FeatureFlagResolver {
  getHardCodeFeatureFlag(): FeatureFlag {
    let featureFlag = new FeatureFlag();

    // Please modify them if you want to enabled feature
    featureFlag.imageUploadQuestion = true;
    featureFlag.appointmentSystem = false;
    return featureFlag;
  }
}
