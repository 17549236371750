import http from "~/setups/api";

class PermissionService {
    getPermissionCheck(uid:string, clinicId:string=""): Promise<any> {
        return http.get("/api/permission/check", {
            headers: {
                "uid": uid,
                "clinicId": clinicId
            }
        });
    }
}

export default new PermissionService();