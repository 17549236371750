const apiEndpoint = {
    "DEV": "https://development-dot-ambii-backend-api-dot-ambii-1534440233327.df.r.appspot.com",
    // STG will use dev uri & dev firebase app, may need to configure stg env for api in the future
    "STG": "https://development-dot-ambii-backend-api-dot-ambii-1534440233327.df.r.appspot.com",
    "PRD": "https://ambii-backend-api-dot-ambii-1534440233327.df.r.appspot.com"
}

const apiSecretKey = {
    "DEV": "q9JLUThlRGwEow",
    // STG will use dev uri & dev firebase app, may need to configure stg env for api in the future
    "STG": "q9JLUThlRGwEow",
    "PRD": "uQXRvp3UgJ/UWQ"
}

import axios, { AxiosInstance } from "axios";

const apiClient: AxiosInstance = axios.create({
  baseURL: apiEndpoint[process.env.STAGE],
  headers: {
    "Content-type": "application/json",
    "secret": apiSecretKey[process.env.STAGE]
  },
});

export default apiClient;