import { AppointmentForm } from "@ambii-com/core"

export const state = () => ({
  appointmentForm: null
})

export const getters = {
}

export const actions = {
  setAppointmentForm({ commit }, appointmentForm: AppointmentForm){
    commit("SET_APPOINTMENT_FORM", appointmentForm)
  },
  resetAppointmentForm({commit}){
    commit("RESET_APPOINTMENT_FORM")
  }
}

export const mutations = {
  SET_APPOINTMENT_FORM(state, appointmentForm: AppointmentForm){
    state.appointmentForm = appointmentForm
  },
  RESET_APPOINTMENT_FORM(state){
    state.appointmentForm = null
  }
}