
import { mapGetters } from "vuex";
import Vue, { inject } from "vue";
import { Clinic, UserRole } from "@ambii-com/core";
import { FeatureFlag } from "~/domain/featureFlag/FeatureFlag";

export default Vue.extend({
  setup() {
    const featureFlag = inject<FeatureFlag>("featureFlag");
    return {
      featureFlag,
    };
  },
  created() {
    if (
      localStorage.getItem("clinicId") &&
      this.$store.getters["auth/isLoggedIn"] &&
      this.$store.getters["auth/getClinicList"]
    ) {
      this.superadminClinic = this.$store.getters["auth/getClinicList"].find(
        (clinic) => {
          if (clinic.id == localStorage.getItem("clinicId")) {
            return true;
          }
        }
      );
    }
  },
  data(): {
    drawer: boolean;
    superadminClinic: Clinic;
  } {
    return {
      drawer: false,
      superadminClinic: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["isLoggedIn", "getClinicName", "checkSuperadmin"]),
    isOnLoginPage() {
      const removeTrialingSlash = (path) => {
        return path.endsWith("/") ? path.slice(0, -1) : path;
      };
      return (
        removeTrialingSlash(this.$nuxt.$route.path) ===
        this.localePath("/login")
      );
    },
    getClinicNameLabel() {
      if (this.$store.getters["auth/getClinicName"]) {
        return this.$store.getters["auth/getClinicName"];
      }
      if (localStorage.getItem("superadminEmail")) {
        return localStorage.getItem("superadminEmail");
      }
      return "";
    },
    userRoleIcon(): string {
      if (this.$store.getters["auth/checkSuperadmin"]) return "";
      return this.$store.getters["auth/getUserRole"] === "STAFF" // TODO: add to UserRole enum
        ? "mdi-account-lock"
        : "mdi-account-check";
    },
    userRoleText(): string {
      if (this.$store.getters["auth/checkSuperadmin"]) return "";
      return this.$store.getters["auth/getUserRole"] === "STAFF" // TODO: add to UserRole enum
        ? `(${this.$t("admin.login.staff")})`
        : `(${this.$t("admin.login.admin")})`;
    },
    activeMenus() {
      if (
        (this.$store.getters["auth/getFormActive"] &&
          this.$store.getters["auth/getAppointmentActive"] &&
          this.$store.getters["auth/getHomepageActive"]) ||
        this.$store.getters["auth/checkSuperadmin"]
      ) {
        return [0, 1, 2, 3];
      } else if (
        (this.$store.getters["auth/getFormActive"] &&
          this.$store.getters["auth/getAppointmentActive"]) ||
        this.$store.getters["auth/checkSuperadmin"]
      ) {
        return [0, 1, 2];
      } else if (this.$store.getters["auth/getFormActive"]) {
        return [0, 2];
      } else {
        return [1];
      }
    },
    menus() {
      const menusArray = [
        {
          id: "form",
          header: {
            icon: "mdi-text-box",
            title: this.$t("nav.form.title"),
            titleColor: !this.$store.getters["auth/getFormActive"]
              ? "grey--text"
              : "primary--text",
            disabled: !this.$store.getters["auth/getFormActive"],
          },
          contents: [
            {
              icon: "mdi-format-list-checks",
              title: this.$t("nav.form.submissions"),
              path: "/form/submissions",
              disabled: false,
            },
            {
              icon: "mdi-playlist-edit",
              title: this.$t("nav.form.build"),
              path: "/form/build",
              disabled:
                this.$store.getters["auth/getUserRole"] === "STAFF" // TODO: add to UserRole enum
                  ? true
                  : false,
            },
            {
              icon: "mdi-table-cog",
              title: this.$t("nav.form.manageSettings"),
              path: "/form/settings",
              disabled:
                this.$store.getters["auth/getUserRole"] === "STAFF" // TODO: add to UserRole enum
                  ? true
                  : false,
            },
          ],
        },
        {
          id: "appointment",
          header: {
            icon: "mdi-calendar-badge",
            title: this.$t("nav.appointment.title"),
            titleColor: !this.$store.getters["auth/getAppointmentActive"]
              ? "grey--text"
              : "primary--text",
            disabled: !this.$store.getters["auth/getAppointmentActive"],
          },
          contents: [
            {
              icon: "mdi-format-list-checkbox",
              title: this.$t("nav.appointment.appointments"),
              path: "/appointment/appointments",
              disabled: false,
            },
            {
              icon: "mdi-calendar",
              title: this.$t("nav.appointment.calendar"),
              path: "/appointment/calendar",
              disabled: false,
            },
            {
              icon: "mdi-calendar-edit",
              title: this.$t("nav.appointment.menu"),
              path: "/appointment/menu",
              disabled: false,
            },
            {
              icon: "mdi-table-cog",
              title: this.$t("nav.appointment.manageSettings"),
              path: "/appointment/settings",
              disabled: false,
            },
          ],
        },
        {
          id: "analytics",
          header: {
            icon: "mdi-chart-bar",
            title: this.$t("nav.form.analytics"),
            titleColor: "primary--text",
            disabled: false,
          },
          contents: [
            {
              icon: "mdi-table-arrow-down",
              title: this.$t("nav.form.exportData"),
              path: "/analytics/export-data",
              disabled: false,
            },
          ],
        },
      ];

      if (this.$store.getters["auth/getHomepageActive"]) {
        menusArray.push({
          id: "homepage",
          header: {
            icon: "mdi-web",
            title: "ホームページ",
            titleColor: "primary--text",
            disabled: false,
          },
          contents: [
            {
              icon: "mdi-post",
              title: "投稿",
              path: "/homepage/posts",
              disabled: false,
            },
          ],
        });
      }
      return menusArray;
    },
  },
  methods: {
    // setLang(lang) {
    //   this.$router.push(this.switchLocalePath(lang))
    //   this.$i18n.locale = lang
    // },
    toPath(path: string) {
      this.$router.push(this.localePath(path));
    },
    async logout() {
      if (confirm(this.$t("app.login.confirmLogout"))) {
        this.superadminClinic = null;
        this.$store.dispatch("auth/setSuperadmin", false);
        await this.$store.dispatch("auth/logout");
        this.$nuxt.$router.push(this.localePath("/login"));
      }
    },
    async superadminClinicSelected() {
      try {
        localStorage.setItem("clinicId", this.superadminClinic.id);
        window.location.reload();
      } catch (error) {
        console.error(error);
      }
    },
  },
  props: {
    title: String,
  },
});
